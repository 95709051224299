.App {
  color: #444;
}

h2 {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 30px;
}

.cv-full-text {
  max-width: calc(10em + 400px);
  text-align: justify;
}

.App-link {
  color: #61dafb;
}

.cv-entry {
  margin: 5px 0px;
  margin-left: 10em;
  max-width: 400px;
}
.cv-date {
  display: block;
  float: left;
  margin-left: -10em;
  width: 9em;
  text-align: right;
}

.left-column {
  width: 50%;
  text-align: center;
  overflow: hidden;
  position: fixed;
  height: 100vh;
  left: 0;
}
.profile-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center top;
}

.icon-link {
  margin: 20px;
  color: white;
  transition: 0.3s;
  opacity: 0.5;
}

.icon-link:hover {
  opacity: 1;
  color: white;
}

@keyframes panning {
  from {
    background-position: 30% 30%;
  }
  to {
    background-position: 50% 50%;
  }
}

.profile-image-bg {
  background-image: url(/src/images/profile-photo-bg.png);
  animation: panning ease-out 2s 1;
}

.cv-entry-with-detail {
  cursor: pointer;
}

.cv-entry-with-detail:hover {
  color: black;
}

.profile-image-fg {
  z-index: 20;
  background-image: url(/src/images/profile-photo-fg.png);
}

header .content {
  z-index: 30;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  padding: 10%;
  box-sizing: border-box;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 20%,
    rgba(0, 0, 0, 0.6) 80%
  );
}

header {
  color: white;
}

header h1 {
  font-size: 3em;
  font-weight: normal;
  margin: 10px 0px;
  padding: 0;
}

header h2 {
  font-size: 1.5em;
  margin: 10px 0px;
  padding: 0;
}

.right-column {
  width: 50%;
  position: absolute;
  right: 0;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100vh;
  box-shadow: -10px -10px 30px rgba(0, 0, 0, 0.25);
}

a {
  color: #444;
  text-decoration: underline;
}
a:hover {
  color: red;
}
.links {
  padding-top: 10px;
}
@media (max-width: 800px) {
  .left-column {
    width: 100%;
    position: relative;
  }
  .right-column {
    width: 100%;
    position: relative;
    box-shadow: none;
  }
}
